<template>
    <user-info-layout>
        <template #heading-text>What's the main activity you will be using Voiceform for?</template>
        <v-card
            v-for="activityOption in activityOptions" :key="activityOption.value"
            class="d-flex align-center mb-4"
            :width="$vuetify.breakpoint.xs?'100%':'364px'"
            :height="$vuetify.breakpoint.xs?48:95"
            :elevation="0"
            outlined
            @click="handleClick(activityOption.value)"
            :color="activityOption.value === activity ? 'primary' : 'primary lighten-5'"
            :style="`border: 1px solid ${activityOption.value === activity?'var(--v-primary-base)':'var(--v-primary-darken1)'} !important;`"
        >
            <v-icon
                :size="$vuetify.breakpoint.xs?25:40"
                class="ml-4 ml-sm-8"
                :color="activityOption.value === activity ? 'white' : 'primary darken-1'"
            >
                {{ activityOption.icon }}
            </v-icon>
            <div
                class="text-subtitle-1 text-sm-h6 ml-2 ml-sm-8 text-spacing"
                :style="{ color: activityOption.value === activity ? 'white' : 'var(--v-primary-darken1'}"
            >
                {{ activityOption.label }}
            </div>
        </v-card>
    </user-info-layout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import UserInfoLayout from '../../layouts/UserInfoLayout.vue';

export default {
    name: "Activity",
    components: {
        UserInfoLayout,
    },
    data() {
        return {
            activityOptions: [
                {
                    label: 'Conduct Research',
                    icon: 'mdi-magnify',
                    value: 'conduct-research'
                },
                {
                    label: 'Get Feedback',
                    icon: 'mdi-message-outline',
                    value: 'get-feedback'
                },
                {
                    label: 'Generate Leads',
                    icon: 'mdi-account-multiple-outline',
                    value: 'generate-leads'
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            activity: 'userInfoForm/activity',
            useType: 'userInfoForm/useType',
        }),
        activityList: {
            get() {
                return this.activity;
            },
            set(value) {
                this.setActivity(value);
            }
        }
    },
    methods: {
        ...mapMutations({
            setActivity: 'userInfoForm/setActivity',
            setIndustry: 'userInfoForm/setIndustry',
            setOrganizationSize: 'userInfoForm/setOrganizationSize',
            setTeam: 'userInfoForm/setTeam',
        }),
        handleClick(val) {
            this.setActivity(val);
            if(this.useType && ['school', 'personal'].includes(this.useType)) {
                this.setIndustry(null);
                this.setOrganizationSize(null);
                this.setTeam(null);
                this.$router.push({ name: 'user-info.reference' });
                return;
            }
            this.$router.push({ name: 'user-info.industry' });
        }
    },
};
</script>

<style scoped lang="scss">
    .v-card {
        border-radius: 4px;
        letter-spacing: 0.15px;
    }
    div.col {
        padding-left: 0;
    }
</style>
